import React from 'react'
import PropTypes from 'prop-types'

const SectionHeader = ({ title, subtitle }) => (
    <header className="text-center">
        <h2>{title}</h2>
        {Boolean(subtitle) && <p>{subtitle}</p>}
    </header>
)
SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    callToAction: PropTypes.function,
}

export default SectionHeader