import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import SectionHeader from '../components/common/SectionHeader'
import Button from '../components/common/Button'

import TagFilterButtons from '../components/home/TagFilterButtons'
import PostFeedFiltered from '../components/home/PostFeedFiltered'
import PostFeedFeatured from '../components/home/PostFeedFeatured'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location }) => {
    const posts = data.allGhostPost.edges
    const [tagToFilterBy, setTagToFilterBy] = useState('all')

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <section className="onboarding-feed" id="onboarding">
                    <div className="container">
                        <SectionHeader
                            title="Onboarding & Training"
                            subtitle="Set your team up for success with the help of onboarding guides and on-demand training sessions led by our Customer Experience Team."
                        />
                        <TagFilterButtons
                            setTagToFilterBy={setTagToFilterBy} 
                            tagToFilterBy={tagToFilterBy} 
                        />
                        <PostFeedFiltered
                            posts={posts} 
                            tagToFilterBy={tagToFilterBy} 
                        />
                    </div>
                </section>
                <section className="feature-spotlight" id="spotlight">
                    <div className="container">
                        <SectionHeader
                            title="Feature Spotlight"
                            subtitle="Get to know our newest features—and the ones that Roadmunk users love the most."
                        />
                        <PostFeedFeatured
                            posts={posts} 
                        />
                    </div>
                </section>
                <section className="latest-releases">
                    <div className="container text-center">
                        <SectionHeader
                            title="Keep up with our latest releases"
                            subtitle="Head over to the Product Updates page to see what's shiny and new."
                        />
                        <Button
                            href="/product-updates"
                            className="button button-clear"
                            buttonText="Check it out"
                        />
                    </div>
                </section>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        featured: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`