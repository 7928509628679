import React from 'react'
import PropTypes from 'prop-types'
import PostCard from '../post/PostCard'

const PostFeedFiltered = ({ posts, tagToFilterBy }) => (
    <div className="post-feed">
        {posts
            .filter(({ node }) => {
                const postTagsArrays = node.tags.map((tag) => {
                    return tag.slug;
                })

                const tagsToFilterOut = [
                    'product-updates',
                    'hash-customer-story',
					'omit-from-homepage'
                ];

                const isNotAPostToFilterOut = !tagsToFilterOut.some(tag => postTagsArrays.includes(tag))

                if (isNotAPostToFilterOut) {
                    const singleTags = node.tags.map((tag) => {
                        const singleTagSlug = tag.slug;
                        return tagToFilterBy === 'all' ? true : singleTagSlug === tagToFilterBy;
                    })
                    const postsContainingSelectedTag = singleTags.some(post => post === true);
                    return postsContainingSelectedTag;
                }
            })
            .map(({ node }) => (
                !node.featured ? <PostCard key={node.id} post={node} /> : null
            ))
        }
    </div>
)

PostFeedFiltered.propTypes = {
    posts: PropTypes.object,
    tagToFilterBy: PropTypes.string,
}

export default PostFeedFiltered
