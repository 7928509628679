import React from 'react'
import PropTypes from 'prop-types'
import PostCard from '../post/PostCard'

const PostFeedFeatured = ({ posts }) => (
    <div className="post-feed">
        {posts.map(({ node }) => (
            node.featured ? <PostCard key={node.id} post={node} /> : null
        ))}
    </div>
) 
PostFeedFeatured.propTypes = {
    posts: PropTypes.object,
}

export default PostFeedFeatured